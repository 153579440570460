import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faCalendarCheck,
  faChevronCircleRight,
  faLocationArrow,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import "./styles/homeInfoPanel.scss";

const LocationInfoSidePanel = ({ location }) => {
  if (location === "Shanghai") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">Shanghai Center</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+861059407141" className="mu-contact-link">
                +86 10 5940 7141
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions/"
                className="mu-contact-link"
              >
                Schedule an Appointment with Admissions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://www.google.com/maps/dir//Shanghai+Tower+501+Yincheng+Middle+Rd+Lu+Jia+Zui,+Lujiazui+Residential+District,+Pudong,+Shanghai+China/@31.233354,121.5052042,15z/data=!4m5!4m4!1m0!1m2!1m1!1s0x35b2706a26763e71:0x352a1022bb7caa28"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
  if (location === "Johannesburg") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">Johannesburg Center</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhone} />
              <a
                href="tel:+27318229032%E2%80%AC%E2%80%AC‬"
                className="mu-contact-link"
              >
                +27 31 822 9032
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions/"
                className="mu-contact-link"
              >
                Schedule an Appointment with Admissions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://www.google.com/maps/dir//Spaces+-+Johannesburg,+Spaces+Atrium+on+5th+Sandton,+Atrium+on+5th+9th+Floor,+5th+St,+Johannesburg,+2196,+South+Africa/@-26.1076743,28.0503073,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1e957336c9b81e51:0xd00ca3e98a953ca3!2m2!1d28.052496!2d-26.1076791"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
  if (location === "Athens") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">Athens Center</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+302111990060‬" className="mu-contact-link">
                +30 21 1199 0060‬
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions/"
                className="mu-contact-link"
              >
                Schedule an Appointment with Admissions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://www.google.com/maps/dir//Ermou+56,+Athina+105+63,+Greece/@37.9765628,23.7259666,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14a1bd3cd0d62279:0xccab443ee7769df6!2m2!1d23.7281553!2d37.9765586"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
  if (location === "Berlin") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">Berlin Center</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:+493016637734" className="mu-contact-link">
                +49 30 16637734
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions/"
                className="mu-contact-link"
              >
                Schedule an Appointment with Admissions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://www.google.com/maps/dir//Greifswalder+Strasse+226,+10405+Berlin,+Germany/@52.5301732,13.4230813,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47a84e054be6e165:0x1296016e1da35642!2m2!1d13.42527!2d52.53017"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
  if (location === "LA") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">
              Meridian University Los Angeles Campus
            </h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faAddressCard} />
              <a
                href="https://www.google.com/maps/place/2450+Colorado+Ave,+Santa+Monica,+CA+90404/@34.0292738,-118.4764942,16z/data=!4m5!3m4!1s0x80c2bb38574fc297:0x899c1d1557d87d29!8m2!3d34.0292694!4d-118.4721168"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                2450 Colorado Ave Suite 100E
                <br />
                Santa Monica, CA 90404
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faLocationArrow} color="#ffffff" />
              <a
                href="https://www.google.com/maps/place/2450+Colorado+Ave,+Santa+Monica,+CA+90404/@34.0292738,-118.4764942,16z/data=!4m5!3m4!1s0x80c2bb38574fc297:0x899c1d1557d87d29!8m2!3d34.0292694!4d-118.4721168"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="/admissions/graduate-admissions/request-for-information"
                className="mu-contact-link"
              >
                Request Information
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="/admissions/graduate-admissions/info-sessions-and-events"
                className="mu-contact-link"
              >
                Attend an Online Info Session
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions"
                className="mu-contact-link"
              >
                Schedule a Call
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="/admissions/graduate-admissions/info-sessions-and-events#inPersonEvents"
                className="mu-contact-link"
              >
                Visit Us
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="/admissions/graduate-admissions/apply-online"
                className="mu-contact-link"
              >
                Start Application
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
  if (location === "SF") {
    return (
      <div id="infoSidePanel">
        <Row className="my-0 my-lg-5 blue-bg">
          <Col xs={12} className="p-3 p-lg-4 contact-us">
            <h4 className="info-title">San Francisco Bay Area Center</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhone} />
              <a href="tel:707-765-1836" className="mu-contact-link">
                +1 (707) 765-1836
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} />
              <a
                href="mailto:admissions@meridianuniversity.edu"
                className="mu-contact-link"
              >
                admissions@meridianuniversity.edu
              </a>
            </p>
            <p className="mb-0">
              <FontAwesomeIcon icon={faCalendarCheck} />
              <a
                href="/admissions/graduate-admissions/schedule-with-admissions/"
                className="mu-contact-link"
              >
                Schedule an Appointment with Admissions
              </a>
            </p>
          </Col>
          <Col xs={12} className="col-12 px-3 px-lg-4">
            <hr className="my-0"></hr>
          </Col>
          <Col xs={12} className="p-3 p-lg-4 apply next-steps">
            <h4 className="info-title">Next Steps</h4>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://services.meridianuniversity.edu/"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Explore The Carnelian Library Collection (Login Required)
              </a>
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faChevronCircleRight} color="#ffffff" />
              <a
                href="https://www.google.com/maps/dir//Meridian+University+-+Bay+Area+Center,+47+6th+St,+Petaluma,+CA+94952/@38.2308652,-122.6422294,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8085b6a5e27340b3:0x288226a221406bf3!2m2!1d-122.6400407!2d38.230861"
                target="_blank"
                rel="noreferrer"
                className="mu-contact-link"
              >
                Get Directions
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
};

export default LocationInfoSidePanel;
